import React from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { Link } from "react-router-dom";
import track from "react-tracking";

import { Layout } from "../../app/legacy-layout";
import { convertMailPlatform } from "../../modules/track";
import { Instructions } from "../../app/instructions";
import { gSuiteConfig } from "../../app/instructions/config/GSuiteConfig";
import { office365Config } from "../../app/instructions/config/Office365Config";

import "./style.scss";

class ConnectManualInstructions extends React.Component<any, any> {
  state = { canProgress: false };
  getDomain = () => {
    return this.props.visitor.email && this.props.visitor.email.split("@")[1];
  };

  isComplete = (count: number, completed: any[]) => {
    if (count === completed.length && !this.state.canProgress) {
      this.setState((prevState: any) => {
        return { ...prevState, canProgress: true };
      });
    }
  };

  render() {
    return (
      <Layout
        type="co-brand--small"
        title="Black Pearl Mail - Connect Manually"
        heading="Mail Connection - Manual"
        platform={this.props.visitor.platform}
        progress={40}
        size="lg"
        flow={this.props.currentFlow}
      >
        {this.props.visitor.platform === "googleapps" ? (
          <Instructions isComplete={this.isComplete} config={gSuiteConfig} />
        ) : (
          <Instructions isComplete={this.isComplete} config={office365Config} />
        )}
        <Link
          to={`${this.props.currentFlow}/on-behalf`}
          className={
            "btn btn-primary btn-big " +
            (!this.state.canProgress ? "disabled" : "")
          }
        >
          Set up complete
        </Link>
      </Layout>
    );
  }
}

type DispatchProps = {
  handleUpdateLocation(arg0: string): void;
};

const mapStateToProps = (state: any) => ({
  visitor: state.visitor,
});

const mapDispatchToProps = (dispatch: Function): DispatchProps => ({
  handleUpdateLocation: (value) => {
    dispatch(push(value));
  },
});

export const ConnectedManualInstructions = connect<{}, DispatchProps, {}>(
  mapStateToProps,
  mapDispatchToProps
)(ConnectManualInstructions);
export const TrackedManualInstructions = track((props) => {
  return {
    page: props.match.path,
    mailPlatform: convertMailPlatform(props.mailPlatform),
  };
})(ConnectedManualInstructions);
