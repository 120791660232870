import React, {useEffect, useState} from 'react';
import track from "react-tracking";
import {Button} from '@blackpearlmail/tui';
import {push} from "connected-react-router";
import {useDispatch} from "react-redux";
import axios from "axios";
import copy from 'copy-to-clipboard';


import {convertMailPlatform} from "../../modules/track";
import {IPage} from "../model";
import {Page} from '../../app/page';

import "./style.scss";


function AuthorizeAccount(props: IPage){
    const {nextPage, stage, stageCount} = props;
    const [isLoading, setIsLoading] = useState(true);
    const [tokenLive, setTokenLive] = useState(false);
    const [userCode, setUserCode] = useState("");
    const [verificationAddress, setVerificationAddress] = useState("");

    const dispatch = useDispatch();

    useEffect(()=> {
        let invalidateToken;

        if(!tokenLive) {
            if(invalidateToken) {
                clearTimeout(invalidateToken);
            }
            axios.get("/api/partner/devicecode")
                .then((response: any)=>{
                    setIsLoading(false);
                    setTokenLive(true);
                    invalidateToken = setTimeout(function(){ setTokenLive(false) }, (response.data.expiresIn *100));
                    setVerificationAddress(response.data.verificationUri);
                    setUserCode(response.data.userCode);
                }).catch((error)=> {
                    console.log(error.response);
                    if(error.response.status === 406) {

                        if(nextPage) {
                            dispatch(push(nextPage))
                        }
                    }
                })
        }

    },[tokenLive, dispatch, nextPage]);

    const launchWindow = () => {
        copy(userCode);
        const child = window.open(`${verificationAddress}`,"Authenticate Black Pearl Mail", "height=600,width=800");
        const timer = setInterval(checkChild, 500);

        function checkChild() {

            if (child && child.closed) {
                const tokenTime = setInterval(()=>{
                    axios.get("/api/partner/devicecode/status")
                        .then((response: any)=>{
                            clearInterval(tokenTime);
                            if(nextPage) {
                                dispatch(push(nextPage))
                            }
                        })
                }, 1500);
                clearInterval(timer);

            }
        }
    };

    return (
        <Page className="acc-auth" isLoading={isLoading}>
            <Page.Header>Let’s authorise your connection.</Page.Header>
            <Page.Content>
                <p>Please click ‘Copy code & proceed’ to continue. More context to what needs to happen next</p>
                <div>
                    <div className="code-wrapper">
                        <span>{userCode}</span>
                        <div onClick={()=>{setTokenLive(false)}}>Refresh</div>
                    </div>
                    <Button className="auth-button" onClick={launchWindow} variant="primary-outline">
                        Copy code & proceed
                    </Button>
                </div>
            </Page.Content>
            <Page.Footer stage={stage} stageCount={stageCount}/>
        </Page>
    );
}


export const TrackedAuthorizeAccount = track(props => {
    return {
        page: props.match.path,
        mailPlatform: convertMailPlatform(props.mailPlatform)
    }})(AuthorizeAccount);
