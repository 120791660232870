import { createStore, applyMiddleware, compose } from 'redux';
import { createBrowserHistory } from 'history';
import { combineReducers } from 'redux';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import { visitorReducer } from "./visitor/reducer";
import {reducer as oidcReducer} from "redux-oidc";


export const history = createBrowserHistory();


const rootReducer = combineReducers({
    oidc: oidcReducer,
    router: connectRouter(history),
    visitor: visitorReducer
});

const persistConfig = {
    key: 'root',
    storage,
    blacklist: ["router"]
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const initialState = {};

const enhancers = [];

const middleware = [
    routerMiddleware(history),
];

if (process.env.NODE_ENV === 'development') {
    // @ts-ignore
    const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;

    if (typeof devToolsExtension === 'function') {
        enhancers.push(devToolsExtension())
    }
}

const composedEnhancers = compose(
    applyMiddleware(...middleware),
    ...enhancers
);

const store = createStore(
    persistedReducer,
    initialState,
    composedEnhancers
);
export const persistor = persistStore(store, undefined, () => { store.getState() });

export default store;