import React from 'react';

import {ContentComponentType} from "./model";


export const PageContent: ContentComponentType = (props: React.HTMLProps<HTMLDivElement>) => {
    const {children} = props

    return (
        <div className="wpg-content">
            {children}
        </div>
    );
};
