import React from 'react';

import {FooterComponentType, IFooterProps} from "./model";


export const PageFooter: FooterComponentType = (props: IFooterProps) => {
    const {children, stage, stageCount} = props

    return (
        <div className="wpg-footer">
            { (stage || stageCount) &&
                <div className="wpg-counter">
                    Step <span>{stage}</span> of <span>{stageCount}</span>
                </div>
            }
            <div>
                {children}
            </div>
        </div>
    );
};
