import React from "react";
import {Redirect, Switch, Route} from "react-router-dom";
import {ReactReduxContext} from "react-redux";

import {Wrapper} from "../wrapper";

import {TrackedLoginPage} from "../../pages/login";
import {TrackedAuthorizeAccount} from "../../pages/authorize-account";
import {TrackedConnectAccount} from "../../pages/connect-account";
import env from "@beam-australia/react-env";


export function ConnectionRoutes(props: any) {

    return (
        <ReactReduxContext.Consumer>
            {({ store }) => {

                const state = store.getState();
                const customProps = {
                    mailPlatform: state.visitor.platform
                };

                return (
                    <Wrapper direction="ltr" background="/images/backgrounds/connection.jpg">
                        <Switch>
                            <Route exact path={`${props.match.url}/login`} render={
                                (routeProps) => {
                                    return <TrackedLoginPage
                                        {...routeProps} {...customProps} stage={1} stageCount={3}
                                        nextPage={`${props.match.url}/authorize`} />
                                }} />
                            <Route exact path={`${props.match.url}/authorize`} render={
                                (routeProps) => {
                                    return <TrackedAuthorizeAccount
                                        {...routeProps} {...customProps} stage={2} stageCount={3}
                                        nextPage={`${props.match.url}/connect`} />
                                }} />
                            <Route exact path={`${props.match.url}/connect`} render={
                                (routeProps) => {
                                    return <TrackedConnectAccount
                                        {...routeProps} {...customProps} stage={3} stageCount={3}
                                        nextPage={env("TRACKS_APP")} />
                                }} />
                            <Redirect from="*" to={"/error/404"}/>
                        </Switch>
                    </Wrapper>
                )
            }}
        </ReactReduxContext.Consumer>
    );
}
