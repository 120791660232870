import React from "react";
import { SignoutCallbackComponent, userSignedOut} from "redux-oidc";
import {useDispatch} from "react-redux";
import {push} from "connected-react-router";

import userManager from "./user-manager";


export function SignoutPage() {
    const dispatch = useDispatch();
    const returnUrl = "/login";

    function signOutUser(){
        userManager.getUser().then((user: any) => {
            if(user != null) {
                userManager.createSignoutRequest({ 'id_token_hint': user.id_token }).then((signoutRequest: any)=> {
                    userManager.signoutRedirectCallback(signoutRequest.url).then(() => {
                        userManager.removeUser().then(() => {
                            dispatch(userSignedOut());
                            dispatch(push(returnUrl));
                        })
                    })
                })
            }
            dispatch(push(returnUrl));
        });
    }

    return (
        // The contents of the callback component will be used while the callback is running and signing the user out
        <SignoutCallbackComponent
            userManager={userManager}
            successCallback={signOutUser}
            errorCallback={error => {
                dispatch(push(returnUrl));
            }} >
            <div/>
        </SignoutCallbackComponent>
    );
}
