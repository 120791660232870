import React from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { ReactReduxContext } from "react-redux";

import { TrackedCreateAccount } from "../../legacy-pages/create-account";
import { TrackedManualInstructions } from "../../legacy-pages/connect-manual-instructions";
import { TrackedOnBehalf } from "../../legacy-pages/on-behalf";
import { TrackedNominateCustomer} from "../../legacy-pages/nominate-customer";
import { TrackedDeployInsights } from "../../legacy-pages/deploy-insights";
import { TrackedThankYou } from "../../legacy-pages/thank-you";
import { TrackedError400 } from "../../legacy-pages/error-400";
import { TrackedErrorNoAccount } from "../../legacy-pages/error-no-account";
import { TrackedErrorAuth } from "../../legacy-pages/error-auth";
import { TrackedErrorSession } from "../../legacy-pages/error-session";


export function ResellerRoutes(props: any) {
    const achievements = ["Black Pearl Mail Account Created", "Mail Connectors Configured"];
    
    return (
        <ReactReduxContext.Consumer>
            {({ store }) => {
                const state = store.getState();
                const customProps = {
                    currentFlow: props.match.url,
                    mailPlatform: state.visitor.platform
                };

                return (
                    <div>
                        <Switch>
                            <Route exact path={`${props.match.url}/create-account`} render={
                                (routeProps) => {
                                    return <TrackedCreateAccount {...routeProps} {...customProps} />
                                }} />
                            <Route exact path={`${props.match.url}/connect-account/manual`} render={
                                (routeProps) => {
                                    return <TrackedManualInstructions {...routeProps} {...customProps} />
                                }} />
                            <Route exact path={`${props.match.url}/on-behalf`} render={
                                (routeProps) => {
                                    return <TrackedOnBehalf {...routeProps} {...customProps} />
                                }} />
                            <Route exact path={`${props.match.url}/nominate-customer`} render={
                                (routeProps) => {
                                    return <TrackedNominateCustomer {...routeProps} {...customProps} />
                                }} />
                            <Route exact path={`${props.match.url}/deploy-insights`} render={
                                (routeProps) => {
                                    return <TrackedDeployInsights {...routeProps} {...customProps} />
                                }} />
                            <Route exact path={`${props.match.url}/thank-you/handed-off`} render={
                                (routeProps) => {
                                    return <TrackedThankYou {...routeProps} {...customProps} achievements={[...achievements, "Successfully Nominated Admin"]} />
                                }} />
                            <Route exact path={`${props.match.url}/thank-you/managed`} render={
                                (routeProps) => {
                                    return <TrackedThankYou {...routeProps} {...customProps} achievements={[...achievements, "Successfully Managing Account"]}/>
                                }} />
                            <Route exact path={`${props.match.url}/error/400`} render={
                                (routeProps) => {
                                    return <TrackedError400 {...routeProps} {...customProps} />
                                }} />
                            <Route exact path={`${props.match.url}/error/auth`} render={
                                (routeProps) => {
                                    return <TrackedErrorAuth {...routeProps} {...customProps} />
                                }} />
                            <Route exact path={`${props.match.url}/error/no-account`} render={
                                (routeProps) => {
                                    return <TrackedErrorNoAccount {...routeProps} {...customProps} />
                                }} />
                            <Route exact path={`${props.match.url}/error/session`} render={
                                (routeProps) => {
                                    return <TrackedErrorSession {...routeProps} {...customProps} />
                                }} />
                            <Redirect from="*" to={"/error/404"}/>
                        </Switch>
                    </div>
                )
            }}
        </ReactReduxContext.Consumer>
    );
}
