import React, {useEffect} from 'react';
import {connect, ReactReduxContext} from 'react-redux';
import {Redirect, Route, Switch} from 'react-router-dom';
import qs from 'qs';

import {updateVisitorAll} from "../modules/store/visitor/actions";

import {ResellerRoutes} from "./routes/reseller";
import {OnboardingRoutes} from "./routes/onboarding";

import {TrackedError404} from "../legacy-pages/error-404";
import {TrackedPublicInstructions} from "../legacy-pages/public-instructions";
import {CallbackPage} from "./auth/callback";
import {SignoutPage} from "./auth/signout";
import {ConnectionRoutes} from "./routes/connect";


function App(props: any) {

    useEffect(() => {
        if(window.location.search !== undefined) {
            const queryString = qs.parse(window.location.search, { ignoreQueryPrefix: true });
            if(Object.keys(queryString).length > 0) {
            props.handleUpdateVisitor(queryString);
            }
        }
    // eslint-disable-next-line
    },[]);

    return (
        <ReactReduxContext.Consumer>
            {({ store }) => {
                const state = store.getState();
                const customProps = {
                    mailPlatform: state.visitor.platform
                };
                return (
                    <Switch>
                        <Route path="/cnct" component={ConnectionRoutes} />
                        <Route path="/reseller" component={ResellerRoutes} />
                        <Route path="/onboarding" component={OnboardingRoutes} />
                        <Route exact path={"/error/404"} render={
                            (routeProps) => {
                                return <TrackedError404 {...routeProps} {...customProps} />
                            }} />
                        <Route path="/callback" component={CallbackPage} />
                        <Route path="/signout" component={SignoutPage} />
                        <Route exact path={"/instructions/office-365"} render={
                            (routeProps) => {
                                return <TrackedPublicInstructions {...routeProps} {...{mailPlatform: "office365"}} />
                            }} />
                        <Route exact path={"/instructions/g-suite"} render={
                            (routeProps) => {
                                return <TrackedPublicInstructions {...routeProps} {...{mailPlatform: "googleapps"}} />
                            }} />
                        <Redirect from="*" to="/error/404"/>
                    </Switch>)
            }}
        </ReactReduxContext.Consumer>
    );
}

type DispatchProps = {
    handleUpdateVisitor(arg0: any): void;
};

const mapStateToProps = (state: any) => ({
    visitor: state.visitor
});

const mapDispatchToProps = (dispatch: Function): DispatchProps => ({
    handleUpdateVisitor: value => {
        dispatch(updateVisitorAll(value));
    }
});

export const ConnectedApp = connect<{}, DispatchProps, {}>(mapStateToProps, mapDispatchToProps)(App);
