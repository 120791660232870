import React, {useState} from 'react';
import track from 'react-tracking';
import {Button} from '@blackpearlmail/tui';

import {Page} from "../../app/page";
import userManager from "../../app/auth/user-manager";
import {IPage} from "../model";
import {convertMailPlatform} from "../../modules/track";

import microsoftLogo from './images/logo-microsoft.svg';
// import googleLogo from './images/logo-google.svg';

import './style.scss';


function LoginPage(props: IPage){
    const {nextPage, stage, stageCount} = props;
    // const [platform, setPlatform] = useState<string | undefined>(undefined);
    const [isLoading, setLoading] = useState(false);

    function login(platformName: string) {
        // setPlatform(platformName);
        setLoading(true);
        userManager
            .signinRedirect({
                acr_values: 'idp:' + platformName,
                redirect_uri: userManager.settings.redirect_uri,
                state: {nextPage}
            },)
            .catch(reason => {
            });
    }

    return (
        <Page className="lgn" isLoading={isLoading}>
            <Page.Header>Sign in.</Page.Header>
            <Page.Content>
                <p>Please sign in with your Microsoft 365 account to connect Insights to your email.</p>
                <div className='sign-in-button-row'>
                    <Button variant="primary-outline" className='sign-in-button' onClick={() => { login("Microsoft"); }}>
                        <img className="sign-in-button-icon" src={microsoftLogo} alt="Microsoft logo"/>
                        <span className='sign-in-button-text'> Sign in with Microsoft</span>
                    </Button>
                </div>
            </Page.Content>
            <Page.Footer stage={stage} stageCount={stageCount}/>
        </Page>
    );
}

export const TrackedLoginPage = track(props => {
    return {
        page: props.match.path,
        mailPlatform: convertMailPlatform(props.mailPlatform)
    }})(LoginPage);
