import React, { useState } from 'react';

export default function CustomInputField(props: any) {

    const [value, setValue] = useState("");
    console.log(props);

    return (
        <div className="input-group">
            <input type="text" className="form-control" 
            onChange={(event: any) => {
                setValue(event.target.value)
                props.form.setFieldValue(props.field.name, event.target.value)
            }}
            value={value}
            onBlur={() => props.form.setFieldTouched(props.field.name, true)}
            name="marketingContact"
            
            placeholder="Email address" />
            <div className="input-group-append">
                <button className={props.field.value === true ? "btn btn-primary" : "btn btn-outline-default"}
                onClick={() => { 
                    setValue("")
                    props.form.setFieldValue(props.field.name, true)
                    props.form.setFieldTouched(props.field.name, true)
                    }} type="button">That's me</button>
                <button className={props.field.value === false ? "btn btn-primary" : "btn btn-outline-default"}
                onClick={() => {
                    setValue("")
                    props.form.setFieldValue(props.field.name, false)
                    props.form.setFieldTouched(props.field.name, true) }} type="button">We don't have one</button>
            </div>
        </div>
    );
}

