import React from 'react';
import track from 'react-tracking';
import { Provider } from 'react-redux';
import { ConnectedRouter } from "connected-react-router";
import { PersistGate } from "redux-persist/integration/react";

import store, { persistor, history } from '../modules/store';
import { ConnectedApp } from "../app";

import "./style.scss";
import {OidcProvider} from "redux-oidc";
import userManager from "../app/auth/user-manager";


const Root = () => {

    return <Provider store={store}>
        <PersistGate loading={null} persistor={ persistor }>
            <OidcProvider store={store} userManager={userManager}>
                <ConnectedRouter history={history}>
                        <ConnectedApp />
                </ConnectedRouter>
            </OidcProvider>
        </PersistGate>
    </Provider>;
};

export default track({}, { process: (ownTrackingData) => ownTrackingData.page ? {event: 'pageview'} : null })(Root);
