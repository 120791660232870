import React from 'react';
import track from "react-tracking";

import { Layout } from "../../app/legacy-layout";
import { convertMailPlatform } from "../../modules/track";


function Error404(props: any) {
    return (
        <Layout type="error"
                title="Black Pearl Mail - 404 Error"
                heading="This is not what you are looking for"
                errorCode={404}
                flow={props.currentFlow}>
            <div className="text-wrapper">
                The page you are looking for doesn't seem to exist, please go back and try find the page you are looking for.
            </div>
        </Layout>
    );
}

export const TrackedError404 = track(props => {
    return {
        page: props.match.path,
        mailPlatform: convertMailPlatform(props.mailPlatform)
    }})(Error404);
