import React from "react";
import {CircularProgressbarWithChildren} from "react-circular-progressbar"

import microsoftLogo from "../images/microsoft-full.png";

import "react-circular-progressbar/dist/styles.css";
import "./progress-bar.scss";

export const ProgressBar = (props:any) => {
    const {percentage} = props;
    return (
        <div className="prg">
            <CircularProgressbarWithChildren
                value={percentage}
                strokeWidth={6}
                >
                <div><img src={microsoftLogo} alt="Microsoft Logo"/></div>
            </CircularProgressbarWithChildren>
            <div className="prg-shield">
                <svg xmlns="http://www.w3.org/2000/svg" width="42.469" height="50.002" viewBox="0 0 42.469 50.002">
                    <g id="Group_2466" data-name="Group 2466" transform="translate(-1183.216 -174.998)">
                        <g id="_003-shield-1" data-name="003-shield-1" transform="translate(1184.216 176)">
                            <g id="Group_2463" data-name="Group 2463" transform="translate(0 0)">
                                <path id="shield" data-name="Path 589"
                                      d="M79.634,5.708,60.807.059a1.406,1.406,0,0,0-.808,0L41.17,5.708a1.406,1.406,0,0,0-1,1.347V27.766a13.378,13.378,0,0,0,3.241,8.2,33.508,33.508,0,0,0,6.754,6.257,60.594,60.594,0,0,0,9.664,5.659,1.406,1.406,0,0,0,1.149,0,60.6,60.6,0,0,0,9.664-5.659A33.51,33.51,0,0,0,77.4,35.962a13.377,13.377,0,0,0,3.241-8.2V7.055A1.406,1.406,0,0,0,79.634,5.708Z" transform="translate(-40.168 0)" strokeWidth="2"/>
                            </g>
                        </g>
                        <g id="Group_2464" data-name="Group 2464" transform="translate(1191.736 187.05)">
                            <path id="check" d="M4.379,74.522l-4.19-4.19a.645.645,0,0,1,0-.912L1.1,68.51a.645.645,0,0,1,.912,0l2.822,2.822,6.045-6.045a.645.645,0,0,1,.912,0l.912.912a.645.645,0,0,1,0,.912L5.29,74.522a.645.645,0,0,1-.912,0Z" transform="translate(6.189 -57.323)"/>
                        </g>
                    </g>
                </svg>
            </div>
        </div>
    )
}
