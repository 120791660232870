import React from 'react';
import track from "react-tracking";

import { Layout } from "../../app/legacy-layout";
import { convertMailPlatform } from "../../modules/track";


function ErrorSession(props: any) {
    return (
        <Layout type="error"
                title="Black Pearl Mail - Timeout Error"
                heading="Your session has timed out"
                flow={props.currentFlow}>
            <div className="text-wrapper">
                To get this resolved as quickly as possible please contact your partner so we can get things moving again.
            </div>
        </Layout>
    );
}

export const TrackedErrorSession = track(props => {
    return {
        page: props.match.path,
        mailPlatform: convertMailPlatform(props.mailPlatform)
    }})(ErrorSession);
