import { instruction } from "../model";

export const office365Config:instruction = {
    introduction: `
        <p>To continue provisioning Black Pearl Mail please follow these instructions within the Office 365 Admin Portal. To get started, log in and go to the Office 365 Admin Console.
        Click <a target="_blank" rel="noopener noreferrer" class="in-text-link" href={"https://outlook.office365.com/ecp/?rfr=Admin_o365&exsvurl=1&mkt=en-GB&Realm=" /* + this.getDomain() */}>here</a> to log in and go to the Exchange Admin Center.</p>
    `,
    instructions: [
        {
            title: "Setup Outbound Connector",
            body: `
                <ol>
                    <li>
                        Select <strong>mail flow</strong> from the left side menu
                        <ul class="first">
                            <li>Select <strong>connectors</strong> from the the top of the page</li>
                            <li>Click on the <strong>+</strong> to trigger a pop up window</li>
                        </ul>
                    </li>
                    <li>
                        In the pop up window, set the following:
                        <ul class="first">
                            <li><strong>From</strong>: Office 365</li>
                            <li><strong>To</strong>: Your organization's email server</li>
                        </ul>
                        Click <strong>Next</strong> to proceed.
                    </li>
                    <li>
                        On the ‘New Connector’ page, set the following:
                        <ul class="first">
                            <li><strong>Name</strong>: Black Pearl Mail Outbound</li>
                            <li>Ensure that the <strong>Turn it on</strong> and <strong>Retain internal Exchange email headers (recommended)</strong> options have been selected</li>
                        </ul>
                        Click <strong>Next</strong> to proceed.
                    </li>
                    <li>
                        Select <strong>Only when I have a transport rule set up that redirects messages to this connector</strong>
                        <div>Click <strong>Next</strong> to proceed.</div>
                    </li>
                    <li>
                        On the <strong>New connector</strong> page:
                        <ul class="first">
                            <li>Click <strong>+</strong> then enter <strong>smtp-global.blackpearlmail.com</strong> into the text field and click <strong>Save</strong></li>
                        </ul>
                        Click <strong>Next</strong> to proceed.
                       
                    </li>
                    <li>
                        Select the following options:
                        <ul class="first">
                            <li><strong>Always use Transport Layer Security (TLS) to secure the connection</strong></li>
                            <li><strong>Issued by a trusted certificate authority (CA)</strong></li>
                            <li><strong>And the subject name or subject alternative name (SAN) matches this domain name </strong></li>
                            <li>Enter *.blackpearlmail.com in the text box</li>
                        </ul>
                        Click <strong>Next</strong> to proceed - and then <strong>Next</strong> again.
                    </li>
                    <li>
                        Under <strong>Validate this connector</strong>:
                        <ul class="first">
                            <li>Click <strong>+</strong> and enter: office365@blackpearlmail.com</li>
                            <li>Click <strong>Ok</strong>.</li>
                            <li>Click <strong>Validate</strong>; wait for this step to be processed</li>
                            <li>Click <strong>Close</strong></li>
                            Note: Ensure that <strong>Check connectivity to ‘smtp-global.blackpearlmail.com’</strong> status is successful.
                            <div>Note: <strong>Send test email</strong> may fail, this is not an issue. Microsoft sends the test email from an unvalidated domain which doesn’t pass security criteria.</div>
                        </ul>
                        Click <strong>Save</strong> to complete setup.
                    </li>
                </ol>
            `
        },
        {
            title: "Setup Inbound Connector",
            body: `
                <ol>
                    <li>
                        Select <strong>mail flow</strong> from the left side menu
                        <ul className="first">
                            <li>While still on the <strong>connectors</strong> page</li>
                            <li>Click on the <strong>+</strong> to trigger a pop up window</li>
                        </ul>
                    </li>
                    <li>
                        In the pop up window, select the following options:
                        <ul className="first">
                            <li><strong>From</strong>: your organization's email server</li>
                            <li><strong>To</strong>: Office 365</li>
                        </ul>
                        Click <strong>Next</strong> to proceed.
                    </li>
                    <li>
                        In the <strong>Name</strong> text box, enter ‘Black Pearl Mail Inbound’
                        <ul className="first">
                            <li>Ensure that the <strong>Turn it on</strong> and <strong>Retain internal Exchange email headers (recommended)</strong> options have been selected</li>
                            Click <strong>Next</strong> to proceed.
                        </ul>
                    </li>
                    <li>
                        Select: <strong>By verifying that the subject name on the certificate that the sending server uses to authenticate with Office 365 match the domain name (recommended)</strong>
                        <ul className="first">
                            <li>In the text box, enter ‘*.blackpearlmail.com’ </li>
                            <li>Click <strong>Next</strong></li>
                        </ul>
                    </li>
                    Confirm your settings, and click <strong>Save</strong> to proceed.
                </ol>
            `
        },
        {
            title: "Setup Internal Bypass rule",
            body: `
                <ol>
                    <li>
                        Select <strong>mail flow</strong> from the left side menu
                        <ul className="first">
                            <li>Select <strong>rules</strong> from the top of the page</li>
                            <li>Click on the <strong>+</strong> which will trigger a dropdown</li>
                            <li>Click <strong>Create a new rule...</strong></li>
                            <li>In the pop up window, scroll to the bottom and click <strong>More Options</strong></li>
                        </ul>
                    </li>
                    <li>
                        Set <strong>Name</strong> to:
                        <ul className="first">
                            <li>‘Internal Bypass’</li>
                            <li>Under <strong>Apply this rule if...</strong> select the following:</li>
                            <ul className="second">
                                <li>A message header...</li>
                                <li>Includes any of these words</li>
                            </ul>
                        </ul>
                    </li>
                    <li>
                        Click <strong>Enter text...</strong> and enter:
                        <ul className="first">
                            <li>X-BP</li>
                            <li>Click <strong>Ok</strong></li>
                        </ul>
                    </li>
                    <li>
                        Click <strong>Enter the words...</strong> and enter:
                        <ul className="first">
                            <li>^*$</li>
                            <li>Click <strong>+</strong> to add text</li>
                            <li>Click <strong>Ok</strong></li>
                        </ul>
                        
                    </li>
                    <li>
                        Under the drop down menu, <strong>Do the following...</strong> select:
                        <ul className="first">
                            <li>Modify the message properties...</li>
                            <li>spam confidence level (SCL)</li>
                        </ul>
                    </li>
                    <li>
                        In the pop up window, under <strong>Specify SCL</strong>, select:
                        <ul className="first">
                            <li>Bypass Spam Filtering </li>
                            <li>Click <strong>Ok</strong></li>
                        </ul>
                        Click <strong>Save</strong> to proceed.
                    </li>
                    <li>
                        Select  the <strong>Internal Bypass</strong> item in the list. If the value in the <strong>Priority</strong> column is not already 0 (zero), do the following:
                        <ul className="first">
                            <li>Click the <strong>Edit</strong>(pencil) icon</li>
                            <li>Set <strong>Priority</strong> to 0</li>
                            <li>Click <strong>Save</strong> to proceed.</li>
                        </ul>
                    </li>
                </ol>
            `
        },
        {
            title: "Setup Black Pearl Mail rule",
            body: `
                <ol>
                    <li>
                        Select <strong>mail flow</strong> from the left side menu
                        <ul className="first">
                            <li>Select <strong>rules</strong> from the top of the page</li>
                            <li>Click on the <strong>+</strong> which will trigger a dropdown</li>
                            <li>Click <strong>Create a new rule...</strong></li>
                            <li>In the pop up window, scroll to the bottom and click <strong>More Options</strong></li>
                            <li>Set the <strong>Name</strong> to ‘Black Pearl Mail Rule’</li>
                        </ul>
                    </li>
                    <li>
                        Under <strong>Apply this rule if...</strong>
                        <ul className="first">
                            <li>Hover over <strong>The sender...</strong></li>
                            <li>Select is <strong>external/internal</strong></li>
                        </ul>
                    </li>
                    <li>
                        In the pop up window, select:
                        <ul className="first">
                            <li>Inside the organization</li>
                            <li>Click <strong>Ok</strong></li>
                        </ul>
                    </li>
                    <li>
                        Under <strong>Do the following...</strong> select:
                        <ul className="first">
                            <li>Redirect the message to...</li>
                            <li>
                                Select <strong>the following connector</strong>
                                <ul className="second">
                                    <li>Choose <strong>Black Pearl Mail Outbound</strong></li>
                                    <li>Click <strong>Ok</strong></li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li>
                        Under <strong>Except if...</strong> click <strong>add exception</strong>, then:
                        <ul className="first">
                            <li>
                                In the dropdown, select <strong>The message properties...</strong>
                                <ul className="second">
                                    <li>Choose <strong>includes the message type</strong></li>
                                    <li>Select <strong>Calendaring</strong> from the new dropdown</li>
                                    <li>Click <strong>Ok</strong></li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li>
                        Under <strong>Except if...</strong> click <strong>add exception</strong> a second time, then:
                        <ul className="first">
                            <li>In the dropdown, select <strong>The message header...</strong></li>
                            <li>Select <strong>matches these text patterns</strong></li>
                        </ul>
                    </li>
                    <li>
                        Click <strong>Enter text...</strong> and enter:
                        <ul className="first">
                            <li>X-BP</li>
                            <li>Click <strong>Ok</strong></li>
                        </ul>
                    </li>
                    <li>
                        Click <strong>Enter the words...</strong> and enter:
                        <ul className="first">
                            <li>^*$</li>
                            <li>Click <strong>+</strong> to add text</li>
                        </ul>
                        Click <strong>Ok</strong> to proceed.
                    </li>
                    <li>
                        Under <strong>Except if...</strong> click <strong>add exception</strong> a third time, then:
                        <ul className="first">
                            <li>
                                In the dropdown, select <strong>The sender...</strong>
                                <ul className="second">
                                    <li>Select <strong>domain is</strong></li>
                                    <li>Enter onmicrosoft.com</li>
                                    <li>Click <strong>+</strong> to add the text</li>
                                    <li>Click <strong>Ok</strong></li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li>
                        Scroll down to <strong>Match sender address in message</strong>:
                        <ul className="first">
                            <li>Change to <strong>envelope</strong></li>
                            <li>Click <strong>Save</strong></li>
                        </ul>
                    </li>
                    <li>
                        Select the <strong>Black Pearl Mail Rule</strong> item in the list. If the value in the <strong>Priority</strong> column is not already 1 (one), do the following:
                        <ul className="first">
                            <li>Click the <strong>Edit</strong>(pencil) icon</li>
                            <li>Set <strong>Priority</strong> to 1</li>
                        </ul>
                    </li>
                    Click <strong>Save</strong> to proceed.
                </ol>
            `
        }
    ]
}
