import React from "react";
import {faCheck, faCircle, faChevronDown} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";


export const InstructionItem: React.FC <InstructionProps> = (props) => {

    const itemInList = (list: any[]) => {
        return (list.indexOf(props.id) >= 0);
    }

    return (
        <div className="instruction-item">
            <div className="heading-wrapper">
                <h3>{ props.title }</h3>
                <FontAwesomeIcon
                    className={"complete-icon " + (itemInList(props.completed) && " selected")}
                    onClick={(event) => {props.toggleComplete(props.id)}}
                    icon={itemInList(props.completed) ? faCheck : faCircle} />

                <FontAwesomeIcon
                    className={"collapse-icon " + (!itemInList(props.open) && " collapsed")}
                    onClick={() => {props.toggleHide(props.id)}}
                    icon={faChevronDown} />
            </div>
            { itemInList(props.open) && <div className="instruction-wrapper">
                { props.children }
                <div className="btn-wrapper">
                    <div className={itemInList(props.completed) ? "btn btn-outline-default" : "btn btn-primary"}
                         onClick={(event) => {props.toggleComplete(props.id)}}>
                        {itemInList(props.completed) ? "Not Done?" : "Done"}
                    </div>
                </div>
            </div> }
        </div>
    );
};


type InstructionProps = {
    id: number;
    title: string;
    completed: number[]
    open: number[]
    toggleHide(id: number): void;
    toggleComplete(id: number): void;
}
