import React from 'react';
import track from "react-tracking";

import { convertMailPlatform } from "../../modules/track";
import { Instructions } from "../../app/instructions";
import { gSuiteConfig } from "../../app/instructions/config/GSuiteConfig";
import { office365Config } from "../../app/instructions/config/Office365Config";

import "./style.scss";

function PublicInstructions(props: any) {

    return (
        <React.Fragment>
        {props.mailPlatform === "googleapps" ?
            <Instructions config={gSuiteConfig}/> :
            <Instructions config={office365Config}/>}
        </React.Fragment>
    );
}

export const TrackedPublicInstructions = track(props => {
    return {
        page: props.match.path,
        mailPlatform: convertMailPlatform(props.mailPlatform)
    }})(PublicInstructions);
