import React from 'react';
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { Link } from 'react-router-dom';
import track from "react-tracking";

import { Layout } from "../../app/legacy-layout";
import { convertMailPlatform } from "../../modules/track";


class OnBehalf extends React.Component<any, {}> {
    render() {
        return (
            <Layout type="co-brand--small"
                   title="Black Pearl Mail - Manage On Behalf"
                   heading="Managed Service Opportunity"
                   platform={this.props.visitor.platform}
                   progress={60}
                   flow={this.props.currentFlow}>
                <div className="text-wrapper">
                    <p>You can either manage Black Pearl Mail on behalf of {this.props.visitor.companyName} as a managed service or hand over control by nominating an administrator in their organization.</p>
                </div>
                <div className="container-buttons">
                    <Link to={`${this.props.currentFlow}/nominate-customer`} className="btn btn-primary-outline">
                        Hand Over Control
                    </Link>
                    <Link to={`${this.props.currentFlow}/deploy-insights`} className="btn btn-primary">
                        Manage On Behalf
                    </Link>
                </div>

            </Layout>
        );
    }
};

type DispatchProps = {
    handleUpdateLocation(arg0: string): void;
};

const mapStateToProps = (state: any) => ({
    visitor: state.visitor
});

const mapDispatchToProps = (dispatch: Function): DispatchProps => ({
    handleUpdateLocation: value => {
        dispatch(push(value));
    }
});

export const ConnectedOnBehalf = connect<{}, DispatchProps, {}>(mapStateToProps, mapDispatchToProps)(OnBehalf);
export const TrackedOnBehalf = track(props => {
    return {
        page: props.match.path,
        mailPlatform: convertMailPlatform(props.mailPlatform)
    }})(ConnectedOnBehalf);
