import { instruction } from "../model";

export const gSuiteConfig:instruction = {
    introduction: `<p>To continue provisioning Black Pearl Mail, please follow these instructions within Google’s G Suite Settings.</p>`,
    instructions: [
    {
        title: "Log in and go to Gmail settings",
        body: `
            <ol>
                <li>
                    If you are already logged in, click <a target="_blank" rel="noopener noreferrer" href={"https://admin.google.com/" + this.getDomain() + "/AdminHome?hl=en#AppDetails:service=email"}>here</a> to go to the Gmail administrator settings or log in to the <a target="_blank" rel="noopener noreferrer" href="https://admin.google.com">admin panel</a>. 
                </li>
                <li>
                    Once logged in, click the menu icon in the top left corner and:
                    <ul class="first">
                        <li>Select <strong>Apps</strong></li>
                        <li>Click on <strong>G Suite</strong></li>
                        <li>Click on <strong>Gmail</strong> to begin your set up</li>
                    </ul>
                </li>
            </ol>
        `
    },
    {
        title: "Setup a new mail route",
        body: `
            <ol>
                <li>
                    Click the <strong>Hosts</strong> item in the list of settings
                    <ul class="first">
                        <li>Click the <strong>Add Route</strong> button</li>
                    </ul>
                </li>
                <li>
                    In the pop-up, add <i>only</i> the following information:
                    <ul class="first">
                        <li><strong>Name:</strong> Black Pearl Mail</li>
                        <li><strong>Host name:</strong> smtp-global.blackpearlmail.com</li>
                        <li><strong>Port:</strong> 25</li>
                    </ul>
                </li>
                <li>
                    Ensure the following:
                    <ul class="first">
                        <li><strong>Specified email server</strong> is set to <strong>single host</strong></li>
                        <li><strong>Require secure transport (TLS)</strong> is selected</li>
                        <li><strong>Require CA signed certificate</strong> is selected</li>
                    </ul>
                </li>
                Click <strong>Save</strong> to proceed.
            </ol>
        `
    },
    {
        title: "Setup SMTP relay",
        body: `
            <ol>
                <li>
                    Go back to the <strong>Settings for Gmail</strong> page
                </li>
                <li>
                    Scroll to the bottom of the page
                    <ul class="first">
                        <li>Click on <strong>Advanced settings</strong></li>
                    </ul>
                </li>
                <li>
                    Scroll to the Routing section
                    <ul class="first">
                        <li>Hover over <strong>SMTP relay service</strong></li>
                        <ul> 
                            <li>Click the <strong>Configure</strong> (or <strong>Add another</strong>) button which appears to the right</li>
                        </ul>
                    </ul>
                </li>
                <li>
                    In the pop-up, do the following:
                    <ul class="first">
                        <li>Set the SMTP relay <strong>short description</strong> to <i>Black Pearl Relay</i></li>
                        <li>Update <strong>Allowed senders</strong> to <i>Only addresses in my domains</i></li>
                        <li>Select the <strong>Only accept mail from the specified IP addresses</strong> option</li>
                        <li>Click <strong>Add IP range</strong></li>
                    </ul>
                </li>
                <li>
                Add the following description and IP range pairs one by one:

                </li>
                <table class="table">
                    <thead>
                        <tr>
                            <th scope="col">Description</th>
                            <th scope="col">IP range</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Black Pearl West US</td>
                            <td>13.86.181.222</td>
                        </tr>
                        <tr>
                            <td>Black Pearl East US</td>
                            <td>13.86.126.189</td>
        
                        </tr>
                        <tr>
                            <td>Black Pearl East US 2</td>
                            <td>40.88.53.55</td>
        
                        </tr>
                        <tr>
                            <td>Black Pearl Central US</td>
                            <td>52.224.189.79</td>
                        </tr>
                    </tbody>
                </table>
                <li> Leave all other settings unchanged </li>
                Click <strong>Add Setting</strong> to proceed
            </ol>
        `
    },
    {
        title: "Configure compliance settings",
        body: `
            <ol>
                <li>
                On the <strong>Advanced settings</strong> page, scroll up to the <strong>Compliance</strong> section. 
                If the Compliance section is missing then return to the Gmail Settings on the previous page 
                and select Compliance from there. Hover over <strong>Content Compliance</strong>
                    <ul class="first">
                        <li>Click the <strong>Configure</strong> (or <strong>Add another</strong>) button which appears to the right.</li>
                    </ul>
                </li>
                <li>
                    In the pop-up, set the following: 
                    <ul class="first">
                        <li>Content compliance <strong>short description</strong> to <i>Black Pearl Compliance</i></li>
                        <li>Select the following options under <strong>Email messages to affect</strong>:
                            <ul>
                                <li>Outbound</li>
                                <li>Internal - sending</li>
                            </ul>
                        </li>
                    </ul>
                </li>
                <li>
                    In the drop down select “If <strong>ALL</strong> of the following match”
                </li>
                <li>
                    In the <strong>Expressions</strong> section:
                    <ul class="first">
                        <li>Click <strong>Add</strong></li>
                        <li>In the drop-down, select <i>Advanced content match</i>
                            <ul>
                                <li>Set <strong>Location</strong> to Full headers</li>
                                <li>Set <strong>Match Type</strong> to Not contains text</li>
                                <li>Set the <strong>Content</strong> value to X-BP</li>
                            </ul>
                        </li>
                    </ul>
                </li>
                Click <strong>Save</strong> to proceed
                <li>
                    To add another expression, click <strong>Add</strong>. 
                    <ul class="first">
                        <li>In the drop-down, select <i>Advanced content match</i>
                            <ul>
                                <li>Set <strong>Location</strong> to <i>Headers + Body</i></li>
                                <li>Set <strong>Match Type</strong> to <i>Not contains text</i></li>
                                <li>Set the <strong>Content</strong> value to <i>BEGIN:VCALENDAR</i></li>
                            </ul>
                        </li>
                    </ul>
                </li>
                Click <strong>Save</strong> to proceed
                <li>
                    To add another expression, click <strong>Add</strong>
                    <ul class="first">
                        <li>
                            In the drop-down select <i>Advanced content match</i>
                            <ul>
                                <li>
                                    Set <strong>location</strong> to Envelope Sender
                                </li>
                                <li>Set <strong>Match Type</strong> to <i>Not Contains text</i></li>
                                <li>Set the <strong>Content</strong> value to <i>google.com</i></li>
                            </ul>
                        </li>
                    </ul>
                </li>

                <li >
                    To add another expression, click <strong>Add</strong>
                    <ul class="first">
                        <li>In the drop-down select <i>Advanced content match</i>
                            <ul>
                                <li>Set <strong>location</strong> to Full headers</li>
                                <li>Set <strong>Match Type</strong> to Not matches regex</li>
                                <li>Set the <strong>Regex</strong> value to <i>(\\W|^)(From:)*(&lt;calendar-notification@google\\.com&gt;)(\\W|$)</i></li>
                            </ul>
                        </li>
                    </ul> 
                </li>
                Click <strong>Save</strong> to proceed
                <li >
                    In the section <strong>If the above expressions match, do the following</strong>, ensure the following checkboxes have been ticked:
                    <ul class="first">
                        <li><i>X-GM-Original-To</i> header (under the <strong>Headers</strong> sub-section)</li>
                        <li>Change route (under the <strong>Route</strong> sub-section)
                            <ul>
                                <li>A drop-down list will appear - select <i>Black Pearl Mail.</i></li>
                            </ul>
                        </li>
                    </ul>
                </li>
                <li >
                    Leave all other settings unchanged
                    <ul class="first">
                        <li>Click <strong>Add Setting</strong> to save the settings and move on to the next step.</li>
                    </ul>
                </li>
            </ol>
        `
    },
    {
        title: "Save your changes",
        body: `
            <li class="firsts">
                Click <strong>Save</strong> at the bottom right corner of the page to apply the changes.
            </li>
        `
    }
]
}
