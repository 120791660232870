import {PageLayout} from "./page";
import {PageHeader} from "./header";
import {PageContent} from "./content";
import {PageFooter} from "./footer";

export const Page = Object.assign(PageLayout, {
    Header: PageHeader,
    Content: PageContent,
    Footer: PageFooter,
});
