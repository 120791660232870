import React from 'react';
import { connect } from "react-redux";
import { push } from "connected-react-router";
import env from "@beam-australia/react-env";
import track from "react-tracking";

import { Layout } from "../../app/legacy-layout";
import { convertMailPlatform } from "../../modules/track";

import './style.scss';


function CreateAccount(props: any) {
        const percentageProgress = props.currentFlow === "/reseller" ? 20 : 25;
        return (
            <Layout type="co-brand--small"
                    appendTitle={false}
                   title="Black Pearl Mail - Get Started Create Account"
                   heading="Create Initial Account for Black Pearl Mail"
                   platform={props.visitor.platform}
                   progress={percentageProgress}
                   flow={props.currentFlow}>
                       {props.currentFlow === "/reseller" ? resellerBody(props) : onboardBody(props)}
            </Layout>
        );
}

function resellerBody(props: any) {

    return (
        <div className="create-account">
            <div className="text-wrapper">
                <p>It's time to setup Black Pearl Mail for {props.visitor.companyName}.</p>
                <p>Please select the platform that you (<strong>{props.visitor.email}</strong>) use to administer all your customer accounts on Black Pearl Mail. (Note: this may not be the same platform as {props.visitor.companyName}).</p>
            </div>
            <div className="container-buttons">
                <a href={env("TRACKS_APP") + `/OAuth/Welcome?email=${props.visitor.email}&type=googleapps&forcePrompt=true&flow=${props.currentFlow}`}
                   className="btn btn-outline-default">
                    Sign in with Google
                </a>
                <a href={env("TRACKS_APP") + `/OAuth/Welcome?email=${props.visitor.email}&type=office365&forcePrompt=true&flow=${props.currentFlow}`}
                   className="btn btn-outline-default">
                    Sign in with Office 365
                </a>
            </div>
        </div>
    );
}

function onboardBody(props: any) {
    const platformName = props.visitor.platform === "googleapps" ? "Google" : "Office 365";

    return (
        <div className="create-account">
            <div className="text-wrapper">
                <p>You've been nominated to be a Black Pearl Mail Admin for {props.visitor.companyName}.</p>
                <p>Please proceed by setting up an account by signing in with {props.visitor.email}.</p>
            </div>
            <div className="btn-layout">
                <a href={env("TRACKS_APP") + `/OAuth/Welcome?email=${props.visitor.email}&type=${props.visitor.platform}&forcePrompt=true&flow=${props.currentFlow}`}
                    className="btn btn-outline-default">
                    Sign in with {platformName}
                </a>
            </div>
        </div>
    );
}

type DispatchProps = {
    handleUpdateLocation(arg0: string): void;
};

const mapStateToProps = (state: any) => ({
    visitor: state.visitor
});

const mapDispatchToProps = (dispatch: Function): DispatchProps => ({
    handleUpdateLocation: value => {
        dispatch(push(value));
    }
});

export const ConnectedCreateAccount = connect<{}, DispatchProps, {}>(mapStateToProps, mapDispatchToProps)(CreateAccount);
export const TrackedCreateAccount = track(props => {
    return {
        page: props.match.path,
        mailPlatform: convertMailPlatform(props.mailPlatform)
    }})(ConnectedCreateAccount);
