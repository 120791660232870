import React from 'react';
import { connect } from "react-redux";
import axios from "axios";
import { push } from "connected-react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faCircle } from "@fortawesome/free-solid-svg-icons";
import env from "@beam-australia/react-env";
import track from "react-tracking";

import { Layout } from "../../app/legacy-layout";
import { convertMailPlatform } from "../../modules/track";

import "./style.scss";


class DeployInsights extends React.Component<any, {}> {
    state = {
        autoDeploy: true
    };

    render() {

        const _sendDataAndProgress = (data: any) => {
            axios.post(env("TRACKS_APP") + "/onboarding/deploy", data, {
                withCredentials:true
            }).then((response: any) => {
                if(this.props.currentFlow === "/reseller") {
                    this.props.handleUpdateLocation(`${this.props.currentFlow}/thank-you/managed`);
                } else {
                    this.props.handleUpdateLocation(`${this.props.currentFlow}/customer-contacts`);
                }
            })
        };

        // const percentageProgress = this.props.currentFlow === "/reseller" ? 80 : 50;

        return (
            <Layout type="co-brand--small"
                    title="Black Pearl Mail - Deploy Insights"
                    heading="Enable Insights Automatically for Users"
                    platform={this.props.visitor.platform}
                    progress={80}
                    flow={this.props.currentFlow}>
                <div className="text-wrapper">
                    <p>Insights is a great tool to help end users understand when and how recipients are interacting with their email.</p>
                    <p>Insights is automatically deployed. Insights is automatically activated for end users within 72 hours of account creation.</p>
                </div>
                <div className="deploy-controls">
                    <div className="text-wrapper">
                        <FontAwesomeIcon
                            className={"auto-deploy-icon " + (this.state.autoDeploy && " selected")}
                            onClick={() => {this.setState({autoDeploy: !this.state.autoDeploy})}}
                            icon={this.state.autoDeploy ? faCheck : faCircle} />
                        <span>
                            Automatically deploy Insights for all new members of {this.props.visitor.companyName}
                        </span>
                    </div>
                </div>
                <div className="btn btn-primary" onClick={() => {_sendDataAndProgress(this.state)}}>
                    Set Insights
                </div>
            </Layout>
        );
    }
};

type DispatchProps = {
    handleUpdateLocation(arg0: string): void;
};

const mapStateToProps = (state: any) => ({
    visitor: state.visitor
});

const mapDispatchToProps = (dispatch: Function): DispatchProps => ({
    handleUpdateLocation: value => {
        dispatch(push(value));
    }
});

export const ConnectedDeployInsights = connect<{}, DispatchProps, {}>(mapStateToProps, mapDispatchToProps)(DeployInsights);
export const TrackedDeployInsights = track(props => {
    return {
        page: props.match.path,
        mailPlatform: convertMailPlatform(props.mailPlatform)
    }})(ConnectedDeployInsights);
