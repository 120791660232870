import React, {useState} from 'react';
import track from "react-tracking";
import {Button} from '@blackpearlmail/tui';
import axios from "axios";

import {convertMailPlatform} from "../../modules/track";
import {ProgressBar} from "./components/progress-bar";
import {IPage} from "../model";
import {Page} from '../../app/page';

import "./style.scss";


function ConnectAccount(props: IPage){
    const {nextPage, stage, stageCount} = props;

    const [percentage, setPercentage] = useState(0);
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
    const [isConnecting, setIsConnecting] = useState(false);
    const [connectionComplete, setConnectionComplete] = useState(false);

    const checkProgress = () => {
        const timer = setInterval(checkTimer, 5000);

        function checkTimer() {
            axios.get("/api/partner/mailrouting")
                .then((response: any) => {
                    const progress = response.data.emailRoutingEvents.filter((item: any) =>item.status === "SUCCESS").length;
                    const failure = response.data.emailRoutingEvents.filter((item: any) =>item.status === "FAILURE").length;
                    if(failure > 0) {
                        setIsConnecting(false);
                        setPercentage(0);
                        clearInterval(timer);
                        setErrorMessage("Something appears to have gone wrong. To get connected please reach out to support.")
                    }
                    if(progress === 5) {
                        setConnectionComplete(true);
                        setIsConnecting(false);
                        setPercentage(100);
                        clearInterval(timer);
                    } else {
                        setPercentage(10+ (progress * 18));
                    }
                })
                .catch((error) => {
                    setIsConnecting(false);
                    setPercentage(0);
                    clearInterval(timer);
                    setErrorMessage("Something appears to have gone wrong. To get connected please reach out to support.")
                });
        }
    };

    return (
        <Page className="acc-connect">
            <Page.Header>Authorization complete!</Page.Header>
            <Page.Content>
                <p>Now lets setup your mail connectors and get your mail routing with Black Pearl Mail.</p>
                <ProgressBar percentage={percentage} />
                <p className="error-message">{errorMessage}</p>
                { connectionComplete ?
                    <Button className="connect-button" onClick={
                        () => {
                            if(nextPage) {
                                window.location.href = nextPage;
                            }
                        }} variant="primary-outline">
                        Get started with Insights
                    </Button> :
                    <Button className="connect-button" disabled={isConnecting || Boolean(errorMessage)} onClick={()=> {
                        if(!isConnecting) {
                            setErrorMessage(undefined);
                            setIsConnecting(true);
                            setPercentage(10);
                            axios.post("/api/partner/mailrouting");
                            checkProgress();
                        }
                    }} variant="primary-outline">
                        Connect now
                    </Button>
                }
            </Page.Content>
            <Page.Footer stage={stage} stageCount={stageCount}/>
        </Page>
    );
}


export const TrackedConnectAccount = track(props => {
    return {
        page: props.match.path,
        mailPlatform: convertMailPlatform(props.mailPlatform)
    }})(ConnectAccount);
