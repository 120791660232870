import React from 'react';
import track from "react-tracking";

import { Layout } from "../../app/legacy-layout";
import { convertMailPlatform } from "../../modules/track";


function Error400(props: any) {
    return (
        <Layout type="error"
                title="Black Pearl Mail - 400 Error"
                heading="Oops something has gone wrong"
                errorCode={400}
                flow={props.currentFlow}>
            <div className="text-wrapper">
                To get this resolved as quickly as possible please contact your partner so we can get things moving again.
            </div>
        </Layout>
    );
}

export const TrackedError400 = track(props => {
    return {
        page: props.match.path,
        mailPlatform: convertMailPlatform(props.mailPlatform)
    }})(Error400);
