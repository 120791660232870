import React from 'react';
import { Helmet } from 'react-helmet';
import blackPearlLogo from "./images/black-pearl-mail-logo.png";
// import officeLogo from "./images/logo-office-365.svg";
// import gSuiteLogo from "./images/logo-g-suite.svg";

import './style.scss';
import {MoonLoader} from "react-spinners";


export const Layout: React.FC <LayoutProps> = (props) => {

    // const displayCorrectCoBrand = (props: any) => {
    //     switch(props.platform) {
    //         case("googleapps"):
    //             return gSuiteLogo;
    //         case("office365"):
    //             return officeLogo;
    //         default:
    //             return;
    //     }
    // };

    const progressClassBuilder = (props: LayoutProps) => {
        if(props.progress !== undefined) {
            return `progress p-${props.progress}`;
        }
        return "";
    };

    const displayBranding = () => {
        switch(props.type) {
            case("error" || "success"):
                return (
                    <React.Fragment>
                        <img src={blackPearlLogo} alt="Black Pearl Mail logo"/>
                        {props.errorCode && props.technical && <span> {props.errorCode} </span>}
                    </React.Fragment>
                );
            case("co-brand"):
                return (
                    <React.Fragment>
                        <img className="black-pearl" src={blackPearlLogo} alt="Black Pearl Mail logo"/>
                    </React.Fragment>
                );
                case("co-brand--small"):
                    return (
                        <React.Fragment>
                            <img className="black-pearl" src={blackPearlLogo} alt="Black Pearl Mail logo"/>

                        </React.Fragment>
                    );
            default:
                return (
                    <img src={blackPearlLogo} alt="Black Pearl Mail logo"/>
                );
        }
    };

    // Not used but keeping in case we want to use it in the future
    // const displayCoBrandHeading = () => {
    //     if(props.type === "co-brand" && props.heading !== undefined && props.appendTitle) {
    //         switch(props.platform) {
    //             case("googleapps"):
    //                 return " Google G Suite";
    //             case("office365"):
    //                 return " Microsoft Office 365";
    //             default:
    //                 return "Microsoft Office 365"
    //         }
    //     }
    //     return ""
    // };

    const defaultTitle = "Black Pearl Welcome";

    const displaySmallCoBrand = (backgroundImage: string) => {
        
        return ( <div className="background-image" style={{backgroundImage: `url(${backgroundImage})`}}>
        <Helmet>
            <title>{props.title ? props.title : defaultTitle}</title>
        </Helmet>
        <div className="modal" tabIndex={-1} role="dialog">
            <div className={"modal-dialog modal-dialog-centered " + (props.size ? ("modal-" + props.size) : "")} role="document">
                <div className="modal-content">
                    <div className="modal-body">
                        <div className={"heading-section " + progressClassBuilder(props)}>
                            <div className={"top-branding " + props.type}>
                                {displayBranding()}
                            </div>
                            
                        </div>
                        <div className={props.className}>
                        {props.heading && <h1 className={`h1-${props.type}`}>{props.heading} </h1>}
                        { props.children }
                        </div>
                        <div className="container-loader">
                            <div className="loader">

                                    <MoonLoader size={80} loading={props.loading ? props.loading : false}/>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>);
    }

    const displayNormal = (backgroundImage: string) => {
        
        return ( <div className="background-image" style={{backgroundImage: `url(${backgroundImage})`}}>
        <Helmet>
            <title>{props.title ? props.title : defaultTitle}</title>
        </Helmet>
        <div className="modal" tabIndex={-1} role="dialog">
            <div className={"modal-dialog modal-dialog-centered " + (props.size ? ("modal-" + props.size) : "")} role="document">
                <div className="modal-content">
                    <div className="modal-body">
                        <div className={"heading-section " + progressClassBuilder(props)}>
                            <div className={"top-branding " + props.type}>
                                {displayBranding()}
                            </div>
                            {props.heading && <h1>{props.heading} </h1>}
                        </div>
                        { props.children }
                    </div>
                </div>
            </div>
        </div>
    </div>);
    }

    const backgroundImage = props.flow === "/reseller" ? "/images/backgrounds/NZ3.jpg" : "/images/backgrounds/NZ4.jpg";
    return (
        props.type === "co-brand--small" ? displaySmallCoBrand(backgroundImage) : displayNormal(backgroundImage)
    );
        

};

type LayoutProps = {
    type: "error" | "co-brand" | "generic" | "success" | "co-brand--small";
    appendTitle?: boolean;
    title?: string;
    heading?: string;
    platform?: string;
    flow: string;
    errorCode?: number;
    loading?: boolean;
    technical?: boolean;
    progress?: number;
    size?: "lg" | "sm";
    className?: string;
}
