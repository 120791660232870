import React from "react";
import {useDispatch} from "react-redux";
import {CallbackComponent} from "redux-oidc";
import {push} from "connected-react-router";

import userManager from "./user-manager";
import axios from "axios";


export function CallbackPage(){
    const dispatch = useDispatch();

    const failPage = "/error/400";

    return (
        <CallbackComponent
            userManager={userManager}
            successCallback={(response) => {
                axios.defaults.headers.authorization = response.token_type + " " + response.access_token;
                dispatch(push(response.state.nextPage))
            }}
            errorCallback={(error) => {
                console.log(error);
                dispatch(push(failPage));
            }}
        >
            <div/>
        </CallbackComponent>
    );
}
