import React from 'react';

import { Layout } from "../../app/legacy-layout";
import track from "react-tracking";
import {convertMailPlatform} from "../../modules/track";


function ErrorNoAccount(props: any) {
    return (
        <Layout type="error"
                title="Black Pearl Mail - Account Error"
                heading="no black pearl mail account found"
                flow={props.currentFlow}>
            <div className="text-wrapper">
                To get this resolved as quickly as possible please contact your partner so we can get things moving again.
            </div>
        </Layout>
    );
}

export const TrackedErrorNoAccount = track(props => {
    return {
        page: props.match.path,
        mailPlatform: convertMailPlatform(props.mailPlatform)
    }})(ErrorNoAccount);
