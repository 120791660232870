import React from 'react';
import { connect } from "react-redux";
import env from "@beam-australia/react-env";
import qs from "qs";
import track from "react-tracking";
import {faCheckCircle} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import { Layout } from "../../app/legacy-layout";
import { convertMailPlatform } from "../../modules/track";
import "./style.scss";


class ThankYou extends React.Component<any, {}> {

    displayAchievements(achievements:string[]) {
        return(<div className="achievements-wrapper">
           { achievements.map(achievement => ( <div className="instruction-item">
            <div className="heading-wrapper">
                    <FontAwesomeIcon
                        className={"complete-icon ach-icon"}    
                        icon={ faCheckCircle} />
                    <h3 className="ach-txt">{achievement}</h3>
            </div>
        </div>))}
        </div>);
    }

    render() {
        return (
            <Layout type="co-brand--small"
                   title="Black Pearl Mail - You’re All Setup"
                   heading="Great Job, you're all setup"
                   platform={this.props.visitor.platform}
                   flow={this.props.currentFlow}
                   progress={100}>
                <div className="content-wrapper">
                    <div className="text-wrapper">
                    Congratulations you've finished the initial setup. Click the button below to continue your Black Pearl experience in the Admin Application.
                    </div>
                    {this.displayAchievements(this.props.achievements)}
                    {(qs.parse(window.location.search, {ignoreQueryPrefix:true}).nominate !== "true") && <a className="btn btn-primary cta-button" href={env("TRACKS_APP")}>
                        Go to the app
                    </a>}
                </div> 
               
            </Layout>
        );
    }
};

const mapStateToProps = (state: any) => ({
    visitor: state.visitor
});

export const ConnectedThankYou = connect<{}, {}, {}>(mapStateToProps, {})(ThankYou);
export const TrackedThankYou = track(props => {
    return {
        page: props.match.path,
        mailPlatform: convertMailPlatform(props.mailPlatform)
    }})(ConnectedThankYou);
