import {
    visitorActionTypes,
    UPDATE_ALL
} from './types'
export const visitorInitialState = {
    platform: undefined,
    technical: false
};

const whitelist = ["platform", "companyName", "technical", "email", "pid", "lid", "name", "token", "timestamp"];

export function visitorReducer(
    state = visitorInitialState,
    action: visitorActionTypes
): any {
    switch (action.type) {
        case UPDATE_ALL: {
            const filtered = whitelist
                .reduce((obj, key) => { if(action.payload[key] !== undefined) {
                    if (key === "pid"){
                        return {...obj, [key]: window.atob(action.payload[key]) }
                    }
                    return {...obj, [key]: action.payload[key]}
                }
                return obj;
                }, {});
            return {
                ...state,
                ...filtered
            }
        }
        default:
            return state
    }
}
