import qs from "qs";

export function convertMailPlatform(platform: MailPlatform) {
    if(window.location.search !== undefined) {
        const queryString = qs.parse(window.location.search, { ignoreQueryPrefix: true });
        if(Object.keys(queryString).length > 0 && queryString.platform !== undefined) {
            platform = String(queryString.platform);
        }
    }

    switch(platform) {
        case("googleapps"):
            return "g suite";
        case("office365" || ""):
            return "office 365";
        default:
            return "unknown";
    }
}

export function convertLeadPlatform(platform: string) {

    switch(platform) {
        case("Google/G Suite"):
            return "googleapps";
        case("M365/O365"):
            return "office365";
        default:
            return "unknown";
    }
}

type MailPlatform = "googleapps" | "office365" | string
