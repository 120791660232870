import React from 'react';

import {HeaderComponentType} from "./model";


export const PageHeader: HeaderComponentType = (props: React.HTMLProps<HTMLDivElement>) => {
    const {children} = props

    return (
        <h1 className="wpg-header">
            {children}
        </h1>
    );
};
