import React, {  useState, useEffect } from "react";
import { InstructionItem } from "./components/InstructionItem";
import { InstructionsProps } from "./model";

import "./style.scss";

export function Instructions(props: InstructionsProps) {
    const [open, setOpen] = useState([0]);
    const [completed, setCompleted] = useState<number[]>([]);

    const toggleComplete = (id: number) => {
        if(completed.indexOf(id) >= 0) {
            const filteredList = completed.filter(item => item !== id);
            setCompleted(filteredList)

        } else {
            const filteredList = open.filter(item => item !== id);
            setOpen([...filteredList, id+1]);
            setCompleted([...completed, id]);
        }
    };

    const toggleHide = (id: number) => {
        if(open.indexOf(id) >= 0) {
            const filteredList = open.filter(item => item !== id);
            setOpen(filteredList);
        } else {
            setOpen([...open, id]);
        }
    };

    useEffect(()=> {
        if(props.isComplete !== undefined) {
            props.isComplete(props.config.instructions.length, completed)
        }
    }, [completed, props])

    const displayAllInstructions = (instructions: any[]) => {
        return instructions.map((item, id) => {
            return (
                <InstructionItem id = {id}
                                 key={id}
                                 title = {item.title}
                                 completed = {completed}
                                 open = {open}
                                 toggleHide = {toggleHide}
                                 toggleComplete = {toggleComplete} >
                    <span dangerouslySetInnerHTML={{ __html:  item.body }} />
                </InstructionItem>
            )
        })
    }

    return (
        <React.Fragment>
            <span dangerouslySetInnerHTML={{ __html: props.config.introduction} } />
            { displayAllInstructions(props.config.instructions) }
        </React.Fragment>
    )
}
