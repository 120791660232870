import React from 'react';
import {getChildByType, removeChildrenByType, typeOfComponent} from "react-nanny";
import {MoonLoader} from "react-spinners";

import {PageFooter} from "./footer";
import {ILayoutProps, PageComponentType} from "./model";

import './style.scss';
import Communicate from "../auth/communicate";


export const PageLayout: PageComponentType = (props: ILayoutProps): JSX.Element => {
    const {children, className, isLoading} = props;


    const footerChild = getChildByType(children, [PageFooter]);
    const cleanChildren = removeChildrenByType(children, [typeOfComponent(footerChild)]);


    return (
        <div className={`wpg ${className? className : ''}`}>
            <Communicate/>
            <div>
                {cleanChildren}
            </div>
            {footerChild}
            { isLoading &&
                <div className="wpg-loading">
                    <div className="loader">
                        <MoonLoader size={100} color="#3b3b3b" />
                    </div>
                </div>
            }
        </div>
    );
};
