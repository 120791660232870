import React from 'react';

import {IWrapper} from "./model";
import blackPearlLogo from "./images/black-pearl-mail-logo.png";

import './style.scss';


export const Wrapper = (props: IWrapper) => {
    const {direction, background} = props

    return (
        <div className="lyt">
            { direction === "ltr" && <BackgroundImage image={background}/> }
            <div className="lyt-content">
                { props.children }
            </div>
            { direction === "rtl" && <BackgroundImage image={background}/> }
        </div>
    );
};

const BackgroundImage = (props: any) => {
    const backgroundStyle = props.image ?
        {
            backgroundPosition: "center",
            backgroundImage: `url(${props.image})`
        } : {}
    return (
        <div className="lyt-image" style={{...backgroundStyle, backgroundColor: "#3b3b3b"}}>
            <img className="lyt-logo" src={blackPearlLogo} alt="Black Pearl Mail"/>
        </div>
    )
}
