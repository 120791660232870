import React from 'react';
import { connect } from "react-redux";
import { Formik, FormikHelpers, Form, Field } from "formik";
import { push } from "connected-react-router";
import axios from "axios";
import env from "@beam-australia/react-env";
import track from "react-tracking";

import { Layout } from "../../app/legacy-layout";
import CustomInputField from "./CustomInputField";
import { convertMailPlatform } from "../../modules/track";

import "./style.scss";
import { isString, isBoolean } from 'util';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';


class CustomerContacts extends React.Component<any, {}> {

    state = {
        submitting: false
    };


    render() {

        const validateCustomInputField = (value: any) => {

            if (value !== null) {
                if (isString(value)){
                    return validateEmail(value) ? undefined : "This is not a valid email address";
                } else if (isBoolean(value)) {
                    return
                } 
            }

            return "Please Select one of the options, or enter a valid email address."
        };

        const validateEmail = (value: string) => {
            const re = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
            return re.test(String(value).toLowerCase());
        };

        const validateInput = (value: string) => {

            if(value.length < 1) {
                return "Please enter an email address."
            }

            if(!validateEmail(value)) {
                return "This is not a valid email address."
            }
        };
        const createRequestObject = (values: any) => {
            let requestList = [];
            if(isBoolean(values.marketing) && values.marketing === true) {
                requestList.push({role: "Marketing", email: this.props.visitor.email})
            } else if(isString(values.marketingContact)) {
                requestList.push({role: "Marketing", email: values.marketing})
            }

            if(isBoolean(values.sales) && values.sales === true) {
                requestList.push({role: "Sales", email: this.props.visitor.email})
            } else if(isString(values.sales)) {
                requestList.push({role: "Sales", email: values.sales})
            }

            if(values.email) {
                requestList.push({role: "Signature", email: values.email})
            }
            return requestList;
        };

        return (
            <Layout type="co-brand--small"
                    title="Black Pearl Mail - Account Contacts"
                    heading="Get the most out of Black Pearl Mail"
                   platform={this.props.visitor.platform}
                   progress={90}
                   flow={this.props.currentFlow}>
                <div className="text-wrapper">
                    <p>To ensure you get the most from the services Black Pearl Mail has to offer, help us get in touch with the right team leaders:</p>
                </div>
                <div className="form-wrapper">
                    <Formik
                        enableReinitialize
                        initialValues={{marketing: null, sales: null, email: this.props.visitor.email}}
                        onSubmit={(values: any, actions: FormikHelpers<any>) => {
                                if(!this.state.submitting) {
                                    this.setState({...this.state, submitting: true},()=> {
                                        axios.post(env("TRACKS_APP") + "/onboarding/staff", createRequestObject(values), {
                                            withCredentials: true
                                        }).then((response: any) => {
                                            this.props.handleUpdateLocation(`${this.props.currentFlow}/thank-you`);
                                        }).catch(()=> {
                                            this.setState({...this.state, submitting: false})
                                        });
                                    });
                                }  
                        }}
                    >
                        {({errors, touched}) => (
                            <Form>
                                <div className="marketing-input">
                                    <h3>Marketing for {this.props.visitor.companyName}</h3>
                                    <div className={`field-validation ${(errors.marketing && touched.marketing ) ? "error" : "normal"}`}>
                                        <Field
                                               name="marketing"
                                               validate={validateCustomInputField}
                                               component={CustomInputField}
                                        />
                                        <div className="error-message">
                                            <FontAwesomeIcon
                                                icon={faExclamationCircle} />
                                             <p>{errors.marketing}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="sales-input">
                                    <h3>Sales for {this.props.visitor.companyName}</h3>
                                    <div className={`field-validation ${(errors.sales && touched.sales ) ? "error" : "normal"}`}>
                                        <Field
                                               name="sales"
                                               validate={validateCustomInputField}
                                               component={CustomInputField}
                                        />
                                        <div className="error-message">
                                            <FontAwesomeIcon
                                                icon={faExclamationCircle} />
                                             <p>{errors.sales}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="signature-input">
                                    <h3>Person that will administer central signatures</h3>
                                    <div className={`input-group--email field-validation ${(errors.email && touched.email ) ? "error" : "normal"}`}>
                                        <Field type="email"
                                               name="email"
                                               validate={validateInput}
                                               className="form-control"
                                               placeholder="Email Address"
                                        />
                                        <div className="error-message">
                                            <FontAwesomeIcon
                                                icon={faExclamationCircle} />
                                             <p>{errors.email}</p>
                                        </div>
                                    </div>
                                </div>
                                    <button className={this.state.submitting || !(!errors.marketing && !errors.sales && !errors.email
                                && touched.marketing && touched.sales) ? "btn btn-primary disabled" : "btn btn-primary"} type="submit">
                                        Save Contacts
                                    </button>
                            </Form>
                        )}
                    </Formik>
                </div>
            </Layout>
        );
    }
};

type DispatchProps = {
    handleUpdateLocation(arg0: string): void;
};

const mapStateToProps = (state: any) => ({
    visitor: state.visitor
});

const mapDispatchToProps = (dispatch: Function): DispatchProps => ({
    handleUpdateLocation: value => {
        dispatch(push(value));
    }
});

export const ConnectedCustomerContacts = connect<{}, DispatchProps, {}>(mapStateToProps, mapDispatchToProps)(CustomerContacts);
export const TrackedCustomerContacts = track(props => {
    return {
        page: props.match.path,
        mailPlatform: convertMailPlatform(props.mailPlatform)
    }})(ConnectedCustomerContacts);
