import React from 'react';
import {connect} from "react-redux";
import {push} from "connected-react-router";
import env from "@beam-australia/react-env";
import { Field, Form, Formik, FormikHelpers } from "formik";
import axios from "axios";
import track from "react-tracking";

import { Layout } from "../../app/legacy-layout";
import { convertMailPlatform } from "../../modules/track";

import "./style.scss";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';


class NominateCustomer extends React.Component<any, {}> {
    state = {
        submitting: false
    };

    render() {

        const validateName = (value: string ) => {
            if (value.length < 1) {
                return "Please enter a Name"
            }
        };

        const validateEmail = (value: string) => {
            const re = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
            return re.test(String(value).toLowerCase());
        };

        const validateInput = (value: string ) => {

            if(value.length < 1) {
                return "Please enter a valid email address"
            }

            if(!validateEmail(value)) {
                return "Please enter a valid email address"
            }
        };



        const createRequestObject = (values: MyFormValues) => {
            return [{role: "Admin", email: values.nominateContact, name: values.nominateName}];

        };


        return (
            <Layout type="co-brand--small"
                   title="Black Pearl Mail - Nominate Customer"
                   heading="Nominate an Administrator for your customer"
                   platform={this.props.visitor.platform}
                   flow={this.props.currentFlow}
                   progress={80}>
                <div className="text-wrapper">
                    <p>Who in {this.props.visitor.companyName}’s organization would you like to nominate as the administrator?</p>
                </div>
                <div className="form-wrapper wide">
                    <Formik
                        initialValues={{nominateContact: "", nominateName: ""}}
                        isInitialValid={false}
                        onSubmit={(values: MyFormValues, actions: FormikHelpers<MyFormValues>) => {
                            if (!this.state.submitting) {
                                this.setState({...this.state, submitting: true},()=> {
                                    axios.post(env("TRACKS_APP") + "/api/invite/invitepartner", createRequestObject(values), {
                                        withCredentials: true
                                    }).then((response: any) => {
                                        this.props.handleUpdateLocation(`${this.props.currentFlow}/thank-you/handed-off`);
                                    }).catch(()=> {
                                        this.setState({...this.state, submitting: false})
                                    });
                                });
                            }
                        }}
                    >
                        {({errors, touched}) => (
                            <Form>
                                <div className="nominate-input">
                                    <div className={`input-group field-validation ${(errors.nominateName && touched.nominateName ) ? "error" : "normal"}`}>
                                        <Field type="text"
                                               name="nominateName"
                                               validate={validateName}
                                               className="form-control"
                                               placeholder="Name"
                                        />
                                        <div className="error-message">
                                            <FontAwesomeIcon
                                                icon={faExclamationCircle} />
                                             <p>{errors.nominateName}</p>
                                        </div>
                                    </div>
                                    <div className={`input-group field-validation ${(errors.nominateContact && touched.nominateContact ) ? "error" : "normal"}`}>
                                        <Field type="text"
                                               name="nominateContact"
                                               validate={validateInput}
                                               className="form-control"
                                               placeholder="Email address"
                                        />
                                        <div className="error-message">
                                            <FontAwesomeIcon
                                                icon={faExclamationCircle} />
                                             <p>{errors.nominateContact}</p>
                                        </div>
                                    </div>
                                    <button className={this.state.submitting || !(!errors.nominateContact && !errors.nominateName && touched.nominateName) ? "btn btn-primary disabled" : "btn btn-primary "} type="submit">
                                        Nominate
                                    </button>
                                        
                                </div>
                            </Form>

                        )}
                    </Formik>
                </div>
            </Layout>
        );
    }
};

type DispatchProps = {
    handleUpdateLocation(arg0: string): void;
};

const mapStateToProps = (state: any) => ({
    visitor: state.visitor
});

const mapDispatchToProps = (dispatch: Function): DispatchProps => ({
    handleUpdateLocation: value => {
        dispatch(push(value));
    }
});

export const ConnectedNominateCustomer = connect<{}, {}, {}>(mapStateToProps, mapDispatchToProps)(NominateCustomer);
export const TrackedNominateCustomer = track(props => {
    return {
        page: props.match.path,
        mailPlatform: convertMailPlatform(props.mailPlatform)
    }})(ConnectedNominateCustomer);

interface MyFormValues {
    nominateContact: string;
    nominateName: string;
}
